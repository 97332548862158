import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

import React from "react";
import "./App.css";
import "./print.css";
import locale from "antd/lib/locale/es_ES";
import { ConfigProvider } from "antd";
import Entry from "components/Entry";
import "dayjs/locale/es";

import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getChartLabelPlugin } from "chart.js-plugin-labels-dv";
import { Provider } from "react-redux";

import configureStore from "./Redux/config";
import { createDocStore } from "@syncstate/core";
import { Provider as Proveedor } from "@syncstate/react";

const store = configureStore();
sessionStorage.setItem("redirect", window.location.pathname);

const almacen = createDocStore({
  usuarios: {
    cargando: false,
    docs: [],
    necesita_cargar: true,
  },
});

dayjs.locale("es");
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

Chart.register(getChartLabelPlugin(), ChartDataLabels);

function App() {
  console.log("APP ", process.env.REACT_APP_APP);
  return (
    <Proveedor store={almacen}>
      <Provider store={store}>
        <ConfigProvider locale={locale}>
          <Entry />
        </ConfigProvider>
      </Provider>
    </Proveedor>
  );
}

export default App;
